<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="deliveryBillSearchFlag" @click="deliveryBillSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="waybillManagementCollectFlag" @click="waybillManagementCollect">揽收</el-button>
                        <el-button type="primary" plain size="mini" v-if="deliveryBilldispatchFlag" @click="waybillbatchdispatch">派车</el-button>
                        <el-button type="primary" plain size="mini" v-if="deliverycanceCarwaybillFlag" @click="canceCarwaybill">取消派车</el-button>
                        <el-button type="primary" plain size="mini" v-if="deliveryConsignmentFlag" @click="consignment">托运单A5纸打印</el-button>
                        <el-button type="primary" plain size="mini" v-if="deliverycanceHandoverFlag" @click="handover">交接单打印</el-button>
                      <el-tooltip class="item" effect="dark" content="提示：请先选择查询条件再进行导出." placement="bottom">
                        <el-button type="primary" plain size="mini" v-if="deliveryExportFlag" @click="deliveryExport">导出</el-button>
                      </el-tooltip>
                        <el-button type="primary" plain size="mini" v-if="deliveryConimmovableFlag" @click="immovable">托运单五联纸打印</el-button>
                        <!-- <el-button type="primary" plain size="mini" @click="immovable">托运单五联纸打印</el-button> -->

                        <el-button  type="primary" plain size="mini" v-if="deliveryBillSearchFlagA" @click="handleClose" :loading="loading">{{ loading ? '查询中 ...' : '高级查询刷新' }}</el-button>

                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入运单号" v-model="AccurateAndUnique_orderNo" size="mini" style="width: 250px" class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                            <!--<el-date-picker-->
                                    <!--v-model="value1"-->
                                    <!--type="daterange"-->
                                    <!--size="mini"-->
                                    <!--range-separator="至"-->
                                    <!--start-placeholder="开始日期"-->
                                    <!--end-placeholder="结束日期">-->
                            <!--</el-date-picker>-->
                        <!--<el-button type="primary" size="mini" icon="el-icon-refresh" @click="deliveryReclgs"></el-button>-->
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="派单管理高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        :with-header="false"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="85px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="运单号" prop="orderNo">
                                                    <el-input  v-model="dataForms.orderNo"></el-input>
                                                </el-form-item>
                                                <el-form-item label="派车状态" prop="assignStatus">
                                                    <el-select v-model="dataForms.assignStatus" placeholder="请选择单据状态" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="未派" value="0"></el-option>
                                                        <el-option label="已派" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="线路" prop="vendeeLine">
                                                    <el-input  maxlength="18" v-model="dataForms.vendeeLine"></el-input>
                                                </el-form-item>
                                                <el-form-item label="是否正泰" prop="chintBusiness">
                                                    <el-select v-model="dataForms.chintBusiness" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="正泰" value="0"></el-option>
                                                        <el-option label="非正泰" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="是否外阜" prop="outGoing">
                                                    <el-select v-model="dataForms.outGoing" placeholder="请选择" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="市内" value="0"></el-option>
                                                        <el-option label="外阜" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <CityLinkageReg @addlist="getCity" :addList="addList"></CityLinkageReg>
<!--                                                <el-form-item label="客户名称" prop="companyName">-->
<!--                                                    <el-input  maxlength="18" v-model="dataForms.companyName"></el-input>-->
<!--                                                </el-form-item>-->
                                                <el-form-item label="发货方名称" prop="consignName">
                                                    <el-input  maxlength="18" v-model="dataForms.consignName"></el-input>
                                                </el-form-item>
                                                <el-form-item label="收货方名称" prop="vendeeName">
                                                    <el-input  maxlength="18" v-model="dataForms.vendeeName"></el-input>
                                                </el-form-item>
                                                <!--<el-form-item label="承运商编码" prop="homeUrl">-->
                                                    <!--<el-input  maxlength="18" v-model="dataForms.carrierId"></el-input>-->
                                                <!--</el-form-item>-->
                                                <el-form-item label="司机名称" prop="driver">
                                                    <el-input  maxlength="18" v-model="dataForms.driver"></el-input>
                                                </el-form-item>
                                                <el-form-item label="车牌号" prop="truckNo">
                                                    <el-input  maxlength="18" v-model="dataForms.truckNo"></el-input>
                                                </el-form-item>
                                                <el-form-item label="派车时间" prop="assignTime">
                                                    <el-date-picker
                                                        v-model="dataForms.assignTime"
                                                        type="date"
                                                        style="width: 100%"
                                                        placeholder="选择日期时间"
                                                        align="right"
                                                        :picker-options="pickerOptions">
                                                    </el-date-picker>
                                                </el-form-item>
<!--                                                <el-form-item label="派车账号" prop="assignUserId">
                                                    <el-input  maxlength="18" v-model="dataForms.assignUserId"></el-input>
                                                </el-form-item>-->

                                                <el-form-item label="创建时间" prop="createTimeBegin">
                                                    <el-date-picker
                                                            v-model="value1"
                                                            style="width: 100%"
                                                            type="daterange"
                                                            size="mini"
                                                            range-separator="至"
                                                            start-placeholder="开始日期"
                                                            end-placeholder="结束日期">
                                                    </el-date-picker>
                                                </el-form-item>

                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini" v-if="deliveryBillSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                                <el-button size="mini" type="danger" @click="resetFrom">重置</el-button>

                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    @setCellColor="setCellColor"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog
                title="批量派车"
                :visible.sync="dialogVisible_delivery"
                width="85%"
                :modal="true"
                center
                :before-close="handleClose_delivery">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">派车信息选择</span>
                    <el-link :underline="false" v-if="deliveryCCTleFlag" style="margin-left: 20px" type="danger">提示：有单据已进行派车操作，提交派车时请仔细检查，以免覆盖。</el-link>
                </div>
                <div>
                    <el-form :model="deliveryForm"  ref="deliveryForm" :rules="deliveryFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="承运商" prop="carriersName">
                                    <el-input v-model="deliveryForm.carriersName" @focus="oninput_carriersId"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                              <el-form-item label="司机" prop="driverName">
                                <el-input v-model="deliveryForm.driverName" @focus="oninput_driver"></el-input>
                              </el-form-item>
<!--                                <el-form-item label="司机" prop="driverName">-->
<!--&lt;!&ndash;                                    <el-input v-model="deliveryForm.driverName" @focus="oninput_driver"></el-input>&ndash;&gt;-->
<!--                                  <el-select v-model="deliveryForm.driverName"-->
<!--                                             filterable placeholder="请选择司机账号"-->
<!--                                             @change="selectVeData"-->
<!--                                  >-->
<!--                                    <el-option-->

<!--                                        v-for="item in options"-->
<!--                                        :key="item.driverId"-->
<!--                                        :label="item.driverName"-->
<!--                                        :value="{ value: item.driverId, label: item.driverName }"-->
<!--                                    >-->
<!--                                    </el-option>-->
<!--                                  </el-select>-->
<!--                                </el-form-item>-->
<!--                                </el-form-item>-->
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="车牌号" prop="truckNo">
<!--                                    <el-input v-model="deliveryForm.truckNo"></el-input>-->
                                  <el-select v-model="deliveryForm.truckNo"
                                             placeholder="请选择车牌号"
                                             @change="selectVeDataDriver"
                                  >
                                    <el-input v-model="deliveryForm.truckNo" placeholder="请选择车牌或者手工输入"></el-input>
                                    <el-option
                                        v-for="item in optionsl"
                                        :key="item"
                                        :label="item"
                                        :value="{ value: item, label: item }"
                                    >
                                    </el-option>
                                    <el-option>
                                    </el-option>
                                  </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" style="text-align: center">
                                <el-button type="primary" size="mini" @click="driverTable">派车</el-button>
                                <el-button type="primary" size="mini" :disabled="saveDataFlag" @click="savewaybill">保 存</el-button>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="18">
                                <el-form-item label="派车备注" prop="tioRemark">
                                    <el-input v-model="deliveryForm.tioRemark"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="">
                                    <el-checkbox v-model="checkedGpsNo">是否选择第三方定位设备</el-checkbox>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6" v-if="checkedGpsNo">
                                <el-form-item label="GPS设备号" prop="gpsNo">
                                    <el-input v-model="deliveryForm.gpsNo"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-top: 10px;">
                <div slot="header">
                    <span style="font-size: 14px">派车单据列表</span>
                    <el-button type="danger" size="mini" style="float: right;" @click="delNowSelectData">移除</el-button>
                </div>
                <div class="cardtable waybill_add">
                    <flight-table
                            :tableData="tableData_driver"
                            :columns="tableCols_driver"
                            :isPagination = 'isPagination'
                            @handleSelectionChange="handleSelectionChange_cardtable"
                    ></flight-table>
                </div>
                <div>
                    <el-form :model="totaldetails"   class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="合计：" prop="orderNo">
                                    总件数： <el-link :underline="false" type="primary">{{totaldetails.pkgQty}}(件数)</el-link>
                                    总重量： <el-link :underline="false" type="primary">{{totaldetails.weight}}(KG)</el-link>
                                    总体积： <el-link :underline="false" type="primary">{{totaldetails.volume}}(m³)</el-link>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
        </el-dialog>
        <el-dialog title="承运商查询" :visible.sync="dialogTableVisible">
            <el-row>
                <el-form :inline="true"  v-model="form_carrier" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="编码" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form_carrier.carriersId" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="form_carrier.carriersName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="companySearch">查询</el-button>
                            <!--<el-button type="primary" plain size="mini" @click="companyOkay">确定</el-button>-->
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add">
                <flight-table
                        :tableData="tableDatadialog"
                        :columns="columns"
                        :pagesizes="pagesizes"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange_carrier"
                        @handleCurrentChange="handleCurrentChange_carriers_a"
                        @clickTable="clickTable"
                        @handleSizeChange = 'handleSizeChange'
                        :total="tableDatadialog_pagination.total"
                        :pageSize="tableDatadialog_pagination.pageSize"
                        :current-page="tableDatadialog_pagination.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
        <el-dialog title="司机查询" :visible.sync="dialogTableVisible_driver">
            <el-row>
                <el-form :inline="true"  v-model="formDriver" size="mini" label-position="center">
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="司机编码" label-width="100px" style="width: 220px;">
                                <el-input size="mini" v-model="formDriver.driverId" style="width: 120px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div class="grid-content">
                            <el-form-item label="名称" label-width="60px" style="width: 220px;">
                                <el-input size="mini" v-model="formDriver.driverName" style="width: 150px;"></el-input>
                            </el-form-item>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <el-button type="primary" plain size="mini" @click="driverSearch">查询</el-button>
                            <!--<el-button type="primary" plain size="mini" @click="driverOkay">确定</el-button>-->
                        </div>
                    </el-col>
                </el-form>
            </el-row>
            <el-row class="waybill_add">
                <flight-table
                        :tableData="tableDatadialog_driver"
                        :columns="columns_driver"
                        :pagesizes="pagesizes_driver"
                        :isPagination='true'
                        @handleSelectionChange="handleSelectionChange_driver"
                        @handleCurrentChange="handleSelectionChange_driver_a"
                        @clickTable="clickTable_driver"
                        :total="tableDatadialog_pagination_driver.total"
                        :pageSize="tableDatadialog_pagination_driver.pageSize"
                        :current-page="tableDatadialog_pagination_driver.pageNum"
                ></flight-table>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import SearchForm from '../../components/common/form.vue'
    import cesTable from '../../components/common/table.vue'
    import flightTable from "../../components/common/flightTable.vue"
    import Drawer from '../../components/common/drawer.vue'
    import arrayQuery from '../../components/arrayQuery.js'
    import axios from 'axios'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    import CityLinkageReg from "../../components/common/cityLinkageReg.vue"
    export default {
        data(){
            return {
                value1:[],
                table: false,
                dialog: false,
                loading: false,
                isAllFlag:true,
                isPagination:false,
                dialogVisible_delivery:false,
                dialogTableVisible: false,
                dialogTableVisible_driver:false,
                deliveryCCTleFlag:false,
                checkedGpsNo:false,
                saveDataFlag:true,
              optionsl:[],
              options:[],
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
                AccurateAndUnique_orderNo:'',
                dataForms:{
                    orderNo:'',
                    vendeeLine:'',
                    assignStatus:'',
                    companyName:'',
                    vendeeName:'',
                    consignName:'',
                    carrierId:'',
                    driver:'',
                    truckNo:'',
                    createTimeBegin:'',
                    createTimeEnd:'',
                    assignTime:'',
                    assignUserId:'',
                    chintBusiness:'',
                    outGoing:''
                },
                totaldetails:{
                    pkgQty:0,
                    weight:0,
                    volume:0
                },
                deliveryForm:{
                    carriersId:'',
                    carriersName:'',
                    driverId:'',
                    driverName:'',
                    truckNo:'',
                    gpsNo:'',
                    tioRemark:''
                },
                form_carrier:{
                    carriersId:'',
                    carriersName:'',
                    companyType:1
                },
                formDriver:{
                    driverId:'',
                    driverName:''
                },
                query_default_license_plate:{
                    carriersId:'',
                    driverId:'',
                },
                tableDatadialog: [],
                tableDatadialog_driver:[],
                columns: [
                    {
                        id: "carriersId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "319",
                        prop: "carriersId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersName",
                        type: "",
                        label: "名称",
                        fixed: false,
                        width: "319",
                        prop: "carriersName",
                        isShow: true,
                        align: "center"
                    }
                ],
                columns_driver:[
                    {
                        id: "driverId",
                        type: "",
                        label: "用户id",
                        fixed: false,
                        width: "319",
                        prop: "id",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "driverName",
                        type: "",
                        label: "用户名称",
                        fixed: false,
                        width: "319",
                        prop: "username",
                        isShow: true,
                        align: "center"
                    }
                ],
                tableDatadialog_pagination:{
                    pageSize:5,
                    pageNum:1,
                    total:0
                },
                tableDatadialog_pagination_driver:{
                    pageSize:5,
                    pageNum:1,
                    total:0
                },
                pagesizes: [5, 10, 15, 20],
                pagesizes_driver:[5, 10, 15, 20],
                deliveryFormrules: {
                    carriersName: [
                        {required: true, message: '请选择承运商', trigger: ['blur', 'change']}
                    ],
                    driverName: [
                        {required: true, message: '请选择司机', trigger: ['blur', 'change']}
                    ],
                    truckNo: [
                        {required: true, message: '车牌号必填', trigger: 'blur'},
                        {
                            pattern: "^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4,5}[A-Z0-9挂学警港澳]{1}$",
                            message: "请输入正确的车牌号"
                        }
                    ],
                    gpsNo: [
                        {required: true, message: '请选择第三方GPS设备号', trigger: 'blur'}
                    ],
                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableData_driver:[],//派单列表数据
                tableCols:[ //表格列数据
                    {label:'派车状态',prop:'assignStatus',width:'100',align:'center',sortable:true,
                        formatter: (row) => {
                            if (row.assignStatus == 0) {
                                return "未派";
                            } else if (row.assignStatus == 1) {
                                return "已派";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'创建时间',prop:'createTime',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'运单编号',prop:'orderNo',width:'180',align:'center'},
                    {label:'线路',prop:'vendeeLine',align:'center',width:'80',sortable:true},
                    {label:'总件数',prop:'pkgQty',align:'center',width:'100'},
                    {label:'总重量（kg）',prop:'weight',align:'center',width:'100'},
                    {label:'总体积（m³）',prop:'volume',align:'center',width:'100',
                        formatter: (row, column) => {
                            return this.getFloat(row.volume,4)
                        }
                    },
                    {label:'收货方名称',prop:'vendeeName',align:'center',width:'220',},
                    {label:'收货方地址',prop:'vendeeAddress',align:'center',width:'400',},
                    {label:'收货方联系人',prop:'vendeeLinkman',align:'center',width:'100',},
                    {label:'收货方电话',prop:'vendeeMobile',align:'center',width:'120',},
                    {label:'收货方省市区信息',prop:'vendeeProvinceName',align:'center',width:'160',
                        formatter: (row) => {
                            let vendeeProvinceName = '';
                            let vendeeCityName = '';
                            let vendeeDistrictName = '';
                            if(row.vendeeProvinceName !== null){
                                vendeeProvinceName = row.vendeeProvinceName
                            }else {
                                vendeeProvinceName = '';
                            }
                            if(row.vendeeCityName !== null){
                                vendeeCityName = row.vendeeCityName;
                            }else {
                                vendeeCityName = '';
                            }
                            if(row.vendeeDistrictName !== null){
                                vendeeDistrictName = row.vendeeDistrictName;
                            }else {
                                vendeeDistrictName = '';
                            }
                            let aa = vendeeProvinceName  + vendeeCityName + vendeeDistrictName
                            return aa
                        }
                    },
                    {label:'发货方省市区信息',prop:'consignProvinceName',align:'center',width:'160',
                        formatter: (row) => {
                            let consignProvinceName = '';
                            let consignCityName = '';
                            let consignDistrictName = '';
                            if(row.consignProvinceName !== null){
                                consignProvinceName = row.consignProvinceName
                            }else {
                                consignProvinceName = '';
                            }
                            if(row.consignCityName !== null){
                                consignCityName = row.consignCityName;
                            }else {
                                consignCityName = '';
                            }
                            if(row.consignDistrictName !== null){
                                consignDistrictName = row.consignDistrictName;
                            }else {
                                consignDistrictName = '';
                            }
                            let aa = consignProvinceName  + consignCityName + consignDistrictName
                            return aa
                        }
                    },
                    {label:'是否正泰业务',prop:'chintBusiness',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.chintBusiness == 1) {
                                return "非正泰";
                            } else if (row.chintBusiness == 0) {
                                return "正泰";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'是否外阜',prop:'outGoing',align:'center',width:'100',
                        formatter: (row) => {
                            if (row.outGoing == 0) {
                                return "市内";
                            } else if (row.outGoing == 1) {
                                return "外阜";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'发货方名称',prop:'consignName',align:'center',width:'200',},
                    {label:'发货方地址',prop:'consignAddress',align:'center',width:'400',},
                    {label:'发货方联系人',prop:'consignLinkman',align:'center',width:'100',},
                    {label:'发货方电话',prop:'consignMobile',align:'center',width:'120',},
                    {label:'承运商名称',prop:'carriersName',align:'center',width:'200',},
                    // {label:'GPS设备号',prop:'gpsNo',align:'center',width:'120'},
                    {label:'司机',prop:'driverName',align:'center',width:'80',},
                    {label:'司机手机',prop:'driverMobile',align:'center',width:'120',},
                    {label:'车牌号',prop:'truckNo',align:'center'},
                    {label:'要求到货时间',prop:'requiredDeliveryTime',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.requiredDeliveryTime == null){
                                return ''
                            }else {
                                return this.$moment(row.requiredDeliveryTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'平台承诺到货时间',prop:'replyDeliveryTime',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.replyDeliveryTime == null){
                                return ''
                            }else {
                                return this.$moment(row.replyDeliveryTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'派车时间',prop:'assignTime',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.assignTime == null){
                                return ''
                            }else {
                                return this.$moment(row.assignTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'派车人员账号',prop:'assignUserId',width:'120',align:'center'},
                    {label:'派车人员手机',prop:'assignUserMobile',align:'center',width:'120',},
                  {label:'派送短信',prop:'dispatchMsgFlag',align:'center',width:'100',
                    formatter: (row) => {
                      if (row.dispatchMsgFlag == 1) {
                        return "发送";
                      } else if (row.dispatchMsgFlag == 0) {
                        return "不发送";
                      } else {
                        return "";
                      }
                    }
                  },
                  {label:'送达短信',prop:'arriveMsgFlag',align:'center',width:'100',
                    formatter: (row) => {
                      if (row.arriveMsgFlag == 1) {
                        return "发送";
                      } else if (row.arriveMsgFlag == 0) {
                        return "不发送";
                      } else {
                        return "";
                      }
                    }
                  },
                    {label:'单据来源',prop:'orderSource',width:'120',align:'center'},
                    // {label:'最近一次修改时间',prop:'modifyTime',width:'160',align:'center'},
                    {label:'订单备注',prop:'remark',width:'160',align:'center'},
                    {label:'派车备注',prop:'tioRemark',align:'center',width:'160'},
                  {label:'EC客户名称',prop:'vendeeGroupName',align:'center',width:'160'},
                ],
                tableCols_driver:[ //表格列数据
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        fixed: "left",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "orderNo",
                        type: "",
                        width:200,
                        label: "运单编号",
                        fixed: false,
                        prop: "orderNo",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "consignName",
                        type: "",
                        width:200,
                        label: "发货方名称",
                        fixed: false,
                        prop: "consignName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "vendeeName",
                        type: "",
                        width:200,
                        label: "收货方名称",
                        fixed: false,
                        prop: "vendeeName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersName",
                        type: "",
                        width:200,
                        label: "承运商名称",
                        fixed: false,
                        prop: "carriersName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "driverId",
                        type: "",
                        width:100,
                        label: "司机编码",
                        fixed: false,
                        prop: "driverId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "driverName",
                        type: "",
                        label: "司机名称",
                        width:100,
                        fixed: false,
                        prop: "driverName",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "truckNo",
                        type: "",
                        width:100,
                        label: "车牌号",
                        fixed: false,
                        prop: "truckNo",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "pkgQty",
                        type: "",
                        label: "件数",
                        fixed: false,
                        prop: "pkgQty",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "weight",
                        type: "",
                        label: "毛重",
                        fixed: false,
                        prop: "weight",
                        isShow: true,
                        align: "center"
                    },{
                        id: "volume",
                        type: "",
                        label: "体积",
                        fixed: false,
                        prop: "volume",
                        isShow: true,
                        align: "center"
                    },

                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                merge_carrier:[],
                merge_driver:[],
                merge_cardtable:[],
                deliveryBillSearchFlag:true,
                deliveryBillSearchFlagA:true,
                deliveryBilldispatchFlag:true,
                waybillManagementCollectFlag:true,
                deliverycanceCarwaybillFlag:true,
                deliveryConsignmentFlag:true,
                deliveryConimmovableFlag:true,
                
                deliverycanceHandoverFlag:true,
                deliveryExportFlag:true,
                addList: {
                    province: "",
                    city: "",
                    area: ""
                },
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer,
            flightTable,
            CityLinkageReg
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_2_3';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let deliveryBillSearch = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_1';
            });
            if(deliveryBillSearch == undefined){
                _this.deliveryBillSearchFlag = false;
            }else {
                _this.deliveryBillSearchFlag = true;
            }
            //高级查询刷新按钮权限
            let deliveryBillSearchA = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_9';
            });
            if(deliveryBillSearchA == undefined){
                _this.deliveryBillSearchFlagA = false;
            }else {
                _this.deliveryBillSearchFlagA = true;
            }
            //派车按钮权限
            let deliveryBilldispatch = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_2';
            });
            if(deliveryBilldispatch == undefined){
                _this.deliveryBilldispatchFlag = false;
            }else {
                _this.deliveryBilldispatchFlag = true;
            }
            //取消派车
            let deliverycanceCarwaybill = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_3';
            });
            if(deliverycanceCarwaybill == undefined){
                _this.deliverycanceCarwaybillFlag = false;
            }else {
                _this.deliverycanceCarwaybillFlag = true;
            }

            //托运单A5纸打印
            let deliveryConsignmentwaybill = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_4';
            });
            if(deliveryConsignmentwaybill == undefined){
                _this.deliveryConsignmentFlag = false;
            }else {
                _this.deliveryConsignmentFlag = true;
            }


             //托运单五联纸打印 
             let deliveryConimmovablewaybill = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_8';
            });
            if(deliveryConimmovablewaybill == undefined){
                _this.deliveryConimmovableFlag = false;
            }else {
                _this.deliveryConimmovableFlag = true;
            }


            //交接单打印

            let deliverycanceHandoverwaybill = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_5';
            });
            if(deliverycanceHandoverwaybill == undefined){
                _this.deliverycanceHandoverFlag = false;
            }else {
                _this.deliverycanceHandoverFlag = true;
            }


            //导出

            let deliveryExport = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_7';
            });
            if(deliveryExport == undefined){
                _this.deliveryExportFlag = false;
            }else {
                _this.deliveryExportFlag = true;
            }


            //派单揽收

            let waybillManagementCollect = currentPageButtons.find(item => {
                return item.menuId == '2_2_3_6';
            });
            if(waybillManagementCollect == undefined){
                _this.waybillManagementCollectFlag = false;
            }else {
                _this.waybillManagementCollectFlag = true;
            }
            //赋值默认时间今天到前两天
//            const now = new Date;
//            const nowData =  now.setDate(now.getDate() - 2);
//            _this.value1 = [nowData,new Date()]
        },
        methods:{
            getCity(data) {
                this.addList.province = data.province;
                this.addList.city = data.city;
                this.addList.area = data.area;
            },
            handleSizeChange(val){
                this.tableDatadialog_pagination.pageSize = val;
                this.companySearch();
            },
            setCellColor:function(e,callback) {
                //e.row：表格那一行的数据；  e.column：表格单元格那一列的信息
                if (e.row.assignStatus === 1) {
                    callback({background: "#16a085",color: '#ffffff'});
                } else {
                    callback({})
                }

            },
            deliveryReclgs(){
                //查询之前先复原表格数据，以免出现第二页查数据混乱
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.loading = true;
                let createTimeBegin;
                let createTimeEnd;
                if(this.value1 == '' || this.value1 == null){
                    createTimeBegin = '';
                    createTimeEnd = '';
                }else {
                    createTimeBegin = this.$moment(this.value1[0]).format("YYYY-MM-DD");
                    createTimeEnd = this.$moment(this.value1[1]).format("YYYY-MM-DD");
                }
                const data = new URLSearchParams();
                data.append('createTimeBegin', createTimeBegin);
                data.append('createTimeEnd', createTimeEnd);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/waybill/queryAssignWaybill`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            materialSummation(){
                const tableData = this.tableData_driver;
                const volume = [];
                const weight = [];
                const pkgQty = [];
                for(var i = 0;i<tableData.length;i++){
                    volume.push(parseFloat(tableData[i].volume));
                    weight.push(parseFloat(tableData[i].weight));
                    pkgQty.push(parseFloat(tableData[i].pkgQty));
                }
                this.totaldetails.volume = this.getFloat(eval(volume.join("+")),4);
                this.totaldetails.weight = this.getFloat(eval(weight.join("+")),2);
                this.totaldetails.pkgQty = eval(pkgQty.join("+"));
            },
            getFloat(number, n) {
                n = n ? parseInt(n) : 0;
                if(n <= 0) {
                    return Math.round(number);
                }
                number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n); //四舍五入
                number = Number(number).toFixed(n); //补足位数
                return number;
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.deliveryBillSearch()
            },
            handleQuery(){
                //快捷查询
                this.loading = true;
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                const data = new URLSearchParams();
                data.append('orderNo', this.AccurateAndUnique_orderNo);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/waybill/queryAssignWaybill`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            //获取表格和表单数据
            async getTableAndForm(){
                //...
                this.deliveryBillSearch();

            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection;
            },
            //订单查询
            deliveryBillSearch(){
                this.loading = true;
                let createTimeBegin;
                let createTimeEnd;
                let assignTime;
                if(this.value1 == '' || this.value1 == null){
                    createTimeBegin = '';
                    createTimeEnd = '';
                }else {
                    createTimeBegin = this.$moment(this.value1[0]).format("YYYY-MM-DD");
                    createTimeEnd = this.$moment(this.value1[1]).format("YYYY-MM-DD");
                }
                if(this.dataForms.assignTime == '' || this.dataForms.assignTime == null){
                    assignTime = ''
                }else {
                    assignTime = this.$moment(this.dataForms.assignTime).format("YYYY-MM-DD")
                }
                const data = new URLSearchParams();
                data.append('orderNo', this.dataForms.orderNo);
                data.append('vendeeLine', this.dataForms.vendeeLine);
                data.append('assignStatus', this.dataForms.assignStatus);
                data.append('companyName', this.dataForms.companyName);
                data.append('vendeeName', this.dataForms.vendeeName);
                data.append('consignName',this.dataForms.consignName);
                data.append('carrierId',this.dataForms.carrierId);
                data.append('driver', this.dataForms.driver);
                data.append('truckNo', this.dataForms.truckNo);
                data.append('createTimeBegin', createTimeBegin);
                data.append('createTimeEnd', createTimeEnd);
                data.append('assignTime',assignTime);
                data.append('assignUserId', this.dataForms.assignUserId);
                data.append('chintBusiness', this.dataForms.chintBusiness);
                data.append('outGoing', this.dataForms.outGoing);
                data.append('proviceAdcode',this.addList.province);
                data.append('cityAdcode',this.addList.city);
                data.append('regionAdcode',this.addList.area);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/waybill/queryAssignWaybill`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
            deliveryBillSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.deliveryBillSearch();
            },
            //打开承运商列表进行选取
            oninput_carriersId(row){
                this.dialogTableVisible = true;
                // this.companySearch();
              this.form_carrier.carriersId="";
              this.form_carrier.carriersName="";
              this.companySearch();
            },
          // 承运商列表
            companySearch(){
                const data = new URLSearchParams();
                data.append('carriersId', this.form_carrier.carriersId);
                data.append('carriersName', this.form_carrier.carriersName);
                data.append('companyType', this.form_carrier.companyType);
                data.append('pageSize', this.tableDatadialog_pagination.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/vehicle/queryVehicleCarriersPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog = res.data.data.items;
                    this.tableDatadialog_pagination.total = res.data.data.total;
                });
            },
            companyOkay(){
                if(this.merge_carrier.length == 0){
                    this.$message({
                        message: '警告，请进行查询并选择一条数据',
                        type: 'warning'
                    });
                }else if(this.merge_carrier.length > 1){
                    this.$message({
                        message: '警告，承运商只允许选择一条数据',
                        type: 'warning'
                    });
                }else {
                    this.deliveryForm.carriersId = this.merge_carrier[0].carriersId;
                    this.deliveryForm.carriersName = this.merge_carrier[0].carriersName;
                    this.dialogTableVisible = false;
                }
            },
            delNowSelectData(){
                if(this.merge_cardtable.length == 0){
                    this.$message({
                        message: '警告，请先选择您要移除的数据',
                        type: 'warning'
                    });
                }else {
                    const a = this.tableData_driver;
                    const b = this.merge_cardtable;
                    this.tableData_driver = this.array_diff(a, b);
                    if( this.tableData_driver.length == 0){
                        this.handleClose_delivery();
                        return false;
                    }
                    this.materialSummation();
                }
            },
            array_diff(a, b) {
                //这段代码是在网上看到的，惊异于大佬的思维
                return diff(a, b).concat( diff(b, a) );
                function diff(a, b) {
                    var c = {};
                    b.forEach(function(o){ c[ JSON.stringify(o) ] = 0 });
                    a.forEach(function(o){ delete c[ JSON.stringify(o) ]; });
                    return Object.keys(c).map(JSON.parse);
                }
            },
            // checkbox的触发接收
            handleSelectionChange_carrier(val) {
                this.merge_carrier = val.val;
            },
            handleCurrentChange_carriers_a(val){
                this.tableDatadialog_pagination.pageNum = val;
                this.companySearch();
            },
            handleSelectionChange_cardtable(val){
                this.merge_cardtable = val.val;
            },
            //  承运商双击事件的行触发
            clickTable(val) {
                this.deliveryForm.carriersId = val.row.carriersId;
                this.deliveryForm.carriersName = val.row.carriersName;
                this.query_default_license_plate.carriersId = val.row.carriersId;
                this.query_default_license_plate.driverId = val.row.driverId;
                this.dialogTableVisible = false;
                this.deliveryForm.driverName = "";
                this.deliveryForm.truckNo = "";
            },
            //选择司机
            oninput_driver(){
            //    selectVeData(data){
            //      const { value, label } = data;
            //      this.deliveryForm.driverId = value;
            //      this.deliveryForm.driverName = label;
                if(this.deliveryForm.carriersId == ''){
                    this.$notify({
                        title: '警告',
                        message: '查询司机前应先查询承运商',
                        type: 'warning'
                    });
                }else {
                    this.dialogTableVisible_driver = true;
                    // this.driverSearch();
                  this.formDriver.driverId = "";
                  this.formDriver.driverName = "";
                  this.driverSearch();
                  this.queryDriverTruckNos();
                  this.deliveryForm.truckNo = "";
                }
            },
          // 承运商所属司机
            driverSearch(){
                const data = new URLSearchParams();
                data.append('driverId', this.formDriver.driverId);
                data.append('driverName', this.formDriver.driverName);
                data.append('carriersId', this.deliveryForm.carriersId);
                data.append('pageSize', this.tableDatadialog_pagination_driver.pageSize);
                data.append('pageNo', this.tableDatadialog_pagination_driver.pageNum);
                axios({
                    method: 'post',
                    // url: `${this.$apiPath}/api/urban/truck/queryDrivers`,
                  url: `${this.$apiPath}/api/urban/vehicle/queryVehicleDriversPage`,
                    data:data
                }).then(res=> {
                    this.tableDatadialog_driver = res.data.data.items;
                    this.tableDatadialog_pagination_driver.total = res.data.data.total;
                  // this.options = res.data.data.items;
                  // console.log(this.tableDatadialog_driver);
                });
            },
          selectVeDataDriver(data){
            const { value, label } = data;
            this.deliveryForm.truckNo = value;
            this.deliveryForm.truckNo = label;
          },
          // 承运商所属司机车牌下拉列表
          queryDriverTruckNos(){
            const data = new URLSearchParams();
            data.append('driverId', this.deliveryForm.driverId);
            console.log( this.deliveryForm.driverId);
            axios.get(`${this.$apiPath}/api/urban/vehicle/queryDriverTruckNos/${this.deliveryForm.driverId}` )
                .then(res=> {
                  this.optionsl = res.data.data;
                  console.log( this.optionsl);
                });
          },
            driverOkay(){
                if(this.merge_driver.length == 0){
                    this.$message({
                        message: '警告，请进行查询并选择一条数据',
                        type: 'warning'
                    });
                }else if(this.merge_driver.length > 1){
                    this.$message({
                        message: '警告，承运商只允许选择一条数据',
                        type: 'warning'
                    });
                }else {
                    this.deliveryForm.driverId = this.merge_driver[0].driverId;
                    this.deliveryForm.driverName = this.merge_driver[0].driverName;
                    this.dialogTableVisible_driver = false;
                }
            },
            handleSelectionChange_driver(val) {
                this.merge_driver = val.val;
            },
            handleSelectionChange_driver_a(val){
                this.tableDatadialog_pagination_driver.pageNum = val;
                this.driverSearch();
            },
            clickTable_driver(val){
                this.deliveryForm.driverId = val.row.driverId;
                this.query_default_license_plate.driverId = val.row.driverId;
                this.deliveryForm.driverName = val.row.driverName;
                this.dialogTableVisible_driver = false;
                this.queryDriverTruckNos();
            },
            query_defaultLicenseplate(){
                const data = new URLSearchParams();
                data.append('driverId', this.query_default_license_plate.driverId);
                data.append('carriersId', this.query_default_license_plate.carriersId);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/vehicle/queryVehicleDriversPage`,
                    data:data
                }).then(res=> {
                    if(res.data.data.items == null || res.data.data.items == 'null'){
                        this.deliveryForm.truckNo = '';
                    }else {
                        this.deliveryForm.truckNo = res.data.data.items;
                    }
                });
            },
            //揽收
            waybillManagementCollect(){
                this.selectionLineChangeHandle();
                var preOrderNos = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '揽收操作必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        preOrderNos.push(this.merge[i].orderNo)
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/waybill/receive`,
                        data:preOrderNos
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '揽收成功',
                                type: 'success'
                            });
                            this.deliveryBillSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                            this.deliveryBillSearch();
                        }
                    });
                }
            },
            //取消派车操作
            canceCarwaybill(){
                this.selectionLineChangeHandle();
                var preOrderNos = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '取消派车操作必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        if(this.merge[i].assignStatus == 0){
                            this.$message({
                                message: '未派车的单子不能取消派车，请重新勾选',
                                type: 'info'
                            });
                            return false;
                        }else {
                            preOrderNos.push(this.merge[i].orderNo)
                        }
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/waybill/cancelAssign`,
                        data:preOrderNos
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '已取消派车',
                                type: 'success'
                            });
                            this.deliveryBillSearch();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                            this.deliveryBillSearch();
                        }
                    });
                }
            },
            //批量派车
            waybillbatchdispatch(){
                this.selectionLineChangeHandle();
                var orderNo = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '派车操作必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        orderNo.push(this.merge[i].orderNo)
                        if(this.merge[i].assignStatus == '1'){
                            this.$message({
                                type: 'warning',
                                message: '您勾选的数据里有单据已派车啦，提交派车时请详细检查哦',
                                customClass:'zZindex'
                            });
                            this.deliveryCCTleFlag = true
                        }
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/waybill/getWaybills`,
                        data:orderNo
                    }).then(res=> {
                        let tabelData= res.data.data;
                        this.tableData_driver = tabelData;
                        this.materialSummation();
                    });
                    this.dialogVisible_delivery = true;
                }
            },
            handleClose_delivery(){
                this.dialogVisible_delivery = false;
                this.$refs.deliveryForm.resetFields();
                this.checkedGpsNo = false;
                this.deliveryCCTleFlag = false;
            },
            driverTable(){
                this.$refs["deliveryForm"].validate(valid => {
                    if (valid) {
                        let tabelData= this.tableData_driver;
                        if(this.checkedGpsNo == true){
                            for(var i =0;i<tabelData.length;i++){
                                tabelData[i].carriersId = this.deliveryForm.carriersId;
                                tabelData[i].carriersName = this.deliveryForm.carriersName;
                                tabelData[i].driverId = this.deliveryForm.driverId;
                                tabelData[i].driverName = this.deliveryForm.driverName;
                                tabelData[i].truckNo = this.deliveryForm.truckNo;
                                tabelData[i].gpsNo = this.deliveryForm.gpsNo;
                                tabelData[i].tioRemark = this.deliveryForm.tioRemark;
                            }
                        }else {
                            for(var i =0;i<tabelData.length;i++){
                                tabelData[i].carriersId = this.deliveryForm.carriersId;
                                tabelData[i].carriersName = this.deliveryForm.carriersName;
                                tabelData[i].driverId = this.deliveryForm.driverId;
                                tabelData[i].driverName = this.deliveryForm.driverName;
                                tabelData[i].truckNo = this.deliveryForm.truckNo;
                                tabelData[i].tioRemark = this.deliveryForm.tioRemark;
                            }
                        }
                        this.saveDataFlag = false;
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
            },
            savewaybill(){
                this.$refs["deliveryForm"].validate(valid => {
                    if (valid) {
                        //派车完毕后提交数据
                        axios({
                            method: 'post',
                            url: `${this.$apiPath}/api/urban/waybill/assign`,
                            data:this.tableData_driver
                        }).then(res=> {
                          console.log( this.tableData_driver);
                            if(res.data.success = true){
                                this.$message({
                                    message: '派车成功',
                                    type: 'success'
                                });
                                this.saveDataFlag = true;
                                this.handleClose_delivery();
                                this.deliveryBillSearch();
                            }else {
                                this.$alert(res.data.msg, '提示', {
                                    confirmButtonText: '确定',
                                });
                            }
                        });
                    } else {
                        this.$message.error("请检查您输入的数据,请核对后重试!");
                    }
                });
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.deliveryBillSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                    //this.$refs.dataForms.resetFields();
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                this.addList.province = '';
                this.addList.city = '';
                this.addList.area = '';
                clearTimeout(this.timer);
                //this.$refs.dataForms.resetFields();
            },
            //托运单打印
            consignment(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '托运单打印必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '托运单打印只能选择单条数据进行操作'
                    });
                }else{
//                    if(this.merge[0].assignStatus == 0){
//                        this.$message({
//                            message: '此单据还没未派车，不能打印托运单！',
//                            type: 'info'
//                        });
//                        return false;
//                    }else {
//                        const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/transportInfoPdf?orderNo=`+ this.merge[0].orderNo;
//                        window.open(routeUrlHref, '_blank');
//                    }
                    const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/transportInfoPdf?orderNo=`+ this.merge[0].orderNo;
                    window.open(routeUrlHref, '_blank');
                }
            },
             //托运单五联纸打印
            immovable(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '托运单打印必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '托运单打印只能选择单条数据进行操作'
                    });
                }else{
                    const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/transportInfoGufenPdf?orderNo=`+ this.merge[0].orderNo;
                    window.open(routeUrlHref, '_blank');
                }
            },
            isAllEqual(array) {
                if (array.length > 0) {
                    return !array.some(function(value, index) {
                        return value !== array[0];
                    });
                } else {
                    return true;
                }
            },
            //交接单打印
            handover(){
                this.selectionLineChangeHandle();

                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '交接单打印必须选中一条数据'
                    });
                }else{
                    let orderNos = '';
                    const carriersIdlist = [];
                    for(var i = 0;i<this.merge.length;i++){
                        if(this.merge[i].assignStatus == 0){
                            this.$message({
                                message: '您勾选的单据中有未派车的单据，不能打印交接单！',
                                type: 'info'
                            });
                            return false;
                        }else {
                            carriersIdlist.push(this.merge[i].carriersId);
                            orderNos += this.merge[i].orderNo+',';
                        }
                    }
                    if(this.isAllEqual(carriersIdlist) == false){
                        this.$message({
                            message: '您勾选的多条单据中不是同一个承运商，不能打印交接单，请检查！',
                            type: 'info'
                        });
                        return false;
                    }else {
                        if (orderNos.length > 0) {
                            orderNos = orderNos.substr(0, orderNos.length - 1);
                        }
                        const routeUrlHref = `${this.$apiPath}/api/urban/reports/export/handoverPapersPdf?orderNos=`+orderNos;
                        window.open(routeUrlHref, '_blank');
                    }
                }

            },
             //重置按钮
            resetFrom() {
                this.$refs['dataForms'].resetFields();
            },
          // 点了这个导出按扭之后，先调用checkExportCount这个接口，返回是true，你再调exportWayBill导出数据接口，如果是false就弹出提示msg

            //导出
            deliveryExport(){
              let createTimeBegin;
              let createTimeEnd;
              let assignTime;
              if(this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null){
                createTimeBegin = ''
              }else {
                createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format("YYYY-MM-DD")
              }
              if(this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null){
                createTimeEnd = ''
              }else {
                createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format("YYYY-MM-DD")
              }
              if(this.dataForms.assignTime == '' || this.dataForms.assignTime == null){
                assignTime = ''
              }else {
                assignTime = this.$moment(this.dataForms.assignTime).format("YYYY-MM-DD")
              }
              const data = new URLSearchParams();
              data.append('orderNo', this.dataForms.orderNo);
              data.append('vendeeLine', this.dataForms.vendeeLine);
              data.append('assignStatus', this.dataForms.assignStatus);
              data.append('companyName', this.dataForms.companyName);
              data.append('vendeeName', this.dataForms.vendeeName);
              data.append('consignName',this.dataForms.consignName);
              data.append('carrierId',this.dataForms.carrierId);
              data.append('driver', this.dataForms.driver);
              data.append('truckNo', this.dataForms.truckNo);
              data.append('createTimeBegin', createTimeBegin);
              data.append('createTimeEnd', createTimeEnd);
              data.append('assignTime',assignTime);
              data.append('assignUserId', this.dataForms.assignUserId);
              data.append('chintBusiness', this.dataForms.chintBusiness);
              data.append('outGoing', this.dataForms.outGoing);
              data.append('proviceAdcode',this.addList.province);
              data.append('cityAdcode',this.addList.city);
              data.append('regionAdcode',this.addList.area);
              data.append('pageSize', this.pagination.pageSize);
              data.append('pageNo', this.pagination.pageNum);
                    var loading = this.$loading({
                        lock: true,
                        text: '导出中，请稍后',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
              axios({
                method: 'post',
                url: `${this.$apiPath}/api/urban/waybill/checkExportCount`,
                data:data,
              }).then(res=> {
                if(res.data.success == true){
                axios({
                  method: 'post',
                  url: `${this.$apiPath}/api/urban/waybill/exportWayBillAssign`,
                  data:data,
                  responseType:"arraybuffer",
                }).then((res)=>{
                  let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }));
                  let a= document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  a.setAttribute('download', `派车单报表.xlsx`);
                  document.body.appendChild(a);
                  a.click();
                  url = window.URL.revokeObjectURL(url);
                  document.body.removeChild(a)
                  loading.close();
                }).catch(error => {
                  this.$message.error('导出失败')
                  loading.close();
                });
                }else {
                  this.$alert(res.data.msg, '提示', {
                    confirmButtonText: '确定',
                  });
                  loading.close();
                }
              });
            }
        }
    }
</script>
<style>
    .zZindex {
        z-index:3000 !important;
    }
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
    .cardtable .ces-table-page{
        height: 100%;
    }
    .cardtable .ces-table{
        height: 100%;
    }
    .waybill_add .ces-table{
        height: 200px;
    }
    .waybill_add .el-table th{
        padding: 0;
        height: 30px;
    }
    .waybill_add .el-table td{
        padding: 0;
        height: 30px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
        margin-bottom: 14px;
    }
</style>
